<script setup lang="ts">
import { RouterView } from 'vue-router'
import { siteSettings } from '@/store'

import Header from '@/components/Header/Header.vue'
import Footer from '@/components/Footer/Footer.vue'
import { modalComponent } from '@/components/Modal'
import Copy from '@/components/Copy/Copy.vue'
import Toast from '@/components/Toast.vue'

document.addEventListener(
  'dblclick',
  function (event) {
    event.preventDefault()
  },
  { passive: false }
)

if (window.location.search && localStorage) {
  const match = RegExp('[?&]' + 'yclid' + '=([^&]*)').exec(window.location.search)
  const yclid = match && decodeURIComponent(match[1].replace(/\+/g, ' '))

  if (yclid) {
    localStorage.setItem('yclid', yclid)
  }
}
</script>

<template>
  <div v-if="siteSettings?.infoMessage" class="p-8px bg-blue-100 text-14m text-center">
    {{ siteSettings.infoMessage }}
  </div>
  <div class="max-w-1440px w-full h-full mx-auto flex flex-col">
    <Header />
    <RouterView />

    <div class="max-w-760px w-full mobile:mt-40px flex justify-center mx-auto">
      <Footer class="mx-20px w-full" />
    </div>
  </div>

  <component :is="modalComponent" />
  <Copy />
  <Toast />

  <div
    class="max-w-1440px w-full absolute top-0 bottom-0 h-full left-1/2 -translate-x-1/2 -z-2 pointer-events-none"
    aria-hidden="true"
  >
    <img
      src="/img/bg/grid.svg"
      alt="grid"
      role="bg"
      class="w-2082px max-w-2082px! absolute top-556px left-1/2 -translate-x-1/2 -z-1 <tablet:top-125px"
    />

    <img
      src="/img/bg/coin-l.png"
      srcset="/img/bg/coin-l@2.png 2x"
      alt="coin l"
      role="bg"
      class="w-66px <tablet:w-42px absolute top-480px left-215px <laptop:-left-18px <tablet:top-236px <tablet:-left-24px"
    />

    <img
      src="/img/bg/money-l.png"
      srcset="/img/bg/money-l@2.png 2x"
      alt="money l"
      role="bg"
      class="w-73px <tablet:w-50px absolute top-186px left-267px <laptop:left-34px <laptop:top-230px <tablet:top-150px <laptop:left-16px <mobile:left-4px <mobile:top-142px"
    />

    <img
      src="/img/bg/coin-r.png"
      srcset="/img/bg/coin-r@2.png 2x"
      alt="coin r"
      role="bg"
      class="w-87px <tablet:w-52px absolute top-410px right-310px <laptop:-right-57px <laptop:top-470px <tablet:top-260px <tablet:-right-20px"
    />

    <img
      src="/img/bg/money-r.png"
      srcset="/img/bg/money-r@2.png 2x"
      alt="money r"
      role="bg"
      class="w-93px <tablet:w-63px absolute top-150px right-268px <laptop:right-30px <laptop:top-210px <tablet:top-114px <tablet:-right-14px"
    />
  </div>
</template>

<style scoped></style>
